import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Listing from "../components/listing"
import Input from "../components/input"

const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Input />
    <Listing />
    <div style={{ maxWidth: `3000px`, marginBottom: `1.45rem` }}>
    </div>
  </Layout>
)

export default IndexPage
