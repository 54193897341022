import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const Post = styled.article `
  box-shadow: 0px 3px 10px rgba(25,17,34, 0.05);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  a {
    color: #333333;
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    font-size: 0.8rem;
  }
  .readMore {
    font-family: georgia, serif;
    text-decoration: underline;
    font-size: 0.8rem;
    color: #33B3DC
  }

`;

const LISTING_QUERY = graphql`
  query BlogPostListing {
    allMarkdownRemark(
      limit: 10
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            name
            path
          }
        }
      }
    }
  }
`

export default function Listing() {
  return (
    <StaticQuery
      query={LISTING_QUERY}
      render={({ allMarkdownRemark }) =>
        allMarkdownRemark.edges.map(({ node }) => (
          <Post key={node.frontmatter.path}>
            <Link to={`/posts${node.frontmatter.path}`}>
              <h2>{node.frontmatter.name}</h2>
            </Link>

            <p> {node.frontmatter.date} </p>
            <p> {node.excerpt} </p>
            <Link class="readMore" to={`/posts${node.frontmatter.path}`}> Read more </Link>
          </Post>
        ))
      }
    />
  )
}
