import React, { useState, useEffect, useRef} from "react"
import {useTitleInput} from "../hooks/useTitleHook.js"

export default function Input() {
  // Declare a new state variable, which we'll call "count"
  const [name,setName] = useTitleInput("");
  const ref = useRef();
  return (
    <div className="main-wrapper" ref={ref}>
      <h1>
        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <input
            type="text"
            onChange={e => setName(e.target.value)}
            value={name}
          />
          <button>Submit</button>
        </form>
      </h1>
      <p onClick={() => console.log(ref.current.className)}>{name}</p>
    </div>
  )
}

