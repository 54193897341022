import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/dan_band2.png"
import flex from "../images/flexbox.jpg"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  background: #89c8ad;
  img {
    margin-bottom: 0;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 auto;
  //max-width: 960px;
  padding: 0.5rem;
`

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <HeaderContainer>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <img
          style={{
            width: "150px",
          }}
          src={logo}
          alt="Wireframe"
        />
      </Link>
      <div>
      <Link
        to="/flexbox"
        style={{
          textDecoration: `none`,
        }}
      >
        <img
          style={{
            width: "53.85px",
            height: "53.85px",
          }}
          src={flex}
          alt="Wireframe"
        />
      </Link>
      <Link
        to="/grid"
        style={{
          textDecoration: `none`,
        }}
      >
        <img
          style={{
            width: "53.85px",
            height: "53.85px",
          }}
          src={flex}
          alt="Wireframe"
        />
      </Link>
        </div>
    </HeaderContainer>
  </HeaderWrapper>
)

/*<nav>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </nav>*/
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
